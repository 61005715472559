var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-page"},[_c('div',{staticClass:"index__banner"},[_c('h1',[_vm._v("Login")]),_c('BreadcrumbNav',{attrs:{"path":_vm.path}})],1),_c('div',{staticClass:"login-container"},[_c('div',{staticClass:"login-form"},[_c('div',{staticClass:"toggle-button"},[_c('button',{class:{
            'button-signin': _vm.mode === 'login',
            active: _vm.mode === 'login',
          },on:{"click":function($event){return _vm.setMode('login')}}},[_vm._v(" Sign In ")]),_c('button',{class:{
            'button-signin': _vm.mode === 'register',
            active: _vm.mode === 'register',
          },on:{"click":function($event){return _vm.setMode('register')}}},[_vm._v(" Register ")])]),(_vm.isLoggedIn)?_c('span',{staticClass:"form-successful-login"},[_vm._v("Login successful! Welcome back!!!")]):_c('span',[_vm._v("Welcome back !!!")]),_c('h2',[_vm._v(_vm._s(_vm.mode === "login" ? "Sign In" : "Register"))]),_c('form',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"text","id":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","id":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('div',{staticClass:"info__form"},[_c('div',{staticClass:"button-container"},[_c('button',{on:{"click":function($event){_vm.mode === 'login' ? _vm.login() : _vm.register()}}},[_vm._v(" "+_vm._s(_vm.mode === "login" ? "Sign In" : "Register")+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.mode === "login" ? "I don't have an account?" : "Already have an account?")+" "),_c('a',{on:{"click":function($event){return _vm.setMode(_vm.mode === 'login' ? 'register' : 'login')}}},[_vm._v(_vm._s(_vm.mode === "login" ? "Register" : "Sign in"))])])])])])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-image"},[_c('img',{attrs:{"src":require("../assets/LoginPage.png"),"alt":"Login Image"}})])
}]

export { render, staticRenderFns }